import { theme } from "@chakra-ui/theme"

const defaultTheme = {
  ...theme,
  fonts: {
    heading: "Teko, sans-serif",
    body: "Lato, system-ui, sans-serif",
    mono: "Menlo, monospace",
  },
  margin: 0,
}

export default defaultTheme
