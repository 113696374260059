exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-android-coming-soon-js": () => import("./../../../src/pages/android-coming-soon.js" /* webpackChunkName: "component---src-pages-android-coming-soon-js" */),
  "component---src-pages-deadend-js": () => import("./../../../src/pages/deadend.js" /* webpackChunkName: "component---src-pages-deadend-js" */),
  "component---src-pages-get-the-app-tsx": () => import("./../../../src/pages/get-the-app.tsx" /* webpackChunkName: "component---src-pages-get-the-app-tsx" */),
  "component---src-pages-portal-payment-tsx": () => import("./../../../src/pages/portal-payment.tsx" /* webpackChunkName: "component---src-pages-portal-payment-tsx" */),
  "component---src-pages-text-me-the-app-tsx": () => import("./../../../src/pages/text-me-the-app.tsx" /* webpackChunkName: "component---src-pages-text-me-the-app-tsx" */),
  "component---src-templates-blog-article-tsx": () => import("./../../../src/templates/blog-article.tsx" /* webpackChunkName: "component---src-templates-blog-article-tsx" */),
  "component---src-templates-jironis-builder-tsx": () => import("./../../../src/templates/jironis-builder.tsx" /* webpackChunkName: "component---src-templates-jironis-builder-tsx" */),
  "component---src-templates-page-builder-tsx": () => import("./../../../src/templates/page-builder.tsx" /* webpackChunkName: "component---src-templates-page-builder-tsx" */)
}

